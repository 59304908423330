import './App.css';
import React from 'react';

function App() {
  return (
    <>
    <div className='container gradient-background-3 container-box'>
      <div className="letter-item-box p-margin-box box-width">A Tribute to</div>
      <div className="flex-grid tborder top-padding-10 flex-grid-box">
        <div className="letter-item p-margin ">D</div>
        <div className="letter-item p-margin ">O</div>
        <div className="letter-item p-margin ">G</div>
        <div className="letter-item p-margin ">S</div>
      </div>
    </div>
    <div className='container gradient-background-1'>
      <div className="flex-grid tborder">
        <div className="letter-item p-margin ">L</div>
        <div className="letter-item p-margin ">A</div>
        <div className="letter-item p-margin ">I</div>
        <div className="letter-item p-margin ">K</div>
        <div className="letter-item p-margin ">A</div>
      </div>
      <div className='content p-content'>
        <div className='image p-w-full p-content'>
          <img src="https://upload.wikimedia.org/wikipedia/en/7/71/Laika_%28Soviet_dog%29.jpg" width="365" height="275">
          </img>
        </div>
        <div className='text-container p-w-full p-content'>
          <div className='born-text'>
            Born - 1954 | Died - 1957
          </div>
          <div className='story-text'>
          <p>Laika was a Soviet space dog who was one of the first animals in space and the first to orbit the Earth. A stray mongrel from the streets of Moscow, she flew aboard the Sputnik 2 spacecraft, launched into low orbit on 3 November 1957. Laika was to be the "flight dog" - a sacrifice to science on a one-way mission to space. The Soviet scientists had planned to euthanise Laika with a serving of poisoned food. She died of overheating hours into the flight, on the craft's fourth orbit.</p>
          </div>
        </div>
      </div>
    </div>
    <div className='container gradient-background-2'>
      <div className="flex-grid tborder">
        <div className="letter-item p-margin ">H</div>
        <div className="letter-item p-margin ">A</div>
        <div className="letter-item p-margin ">C</div>
        <div className="letter-item p-margin ">H</div>
        <div className="letter-item p-margin ">I</div>
        <div className="letter-item p-margin ">K</div>
        <div className="letter-item p-margin ">O</div>
      </div>
      <div className='content p-content'>
        <div className='text-container p-w-full p-content'>
          <div className='born-text'>
            Born - 1923 | Died - 1935
          </div>
          <div className='story-text'>
          Hachikō was a Japanese Akita dog remembered for his remarkable loyalty to his owner, Hidesaburō Ueno. In 1924, Hidesaburō Ueno, a professor in the agriculture department at the Tokyo Imperial University, took Hachikō as a pet and brought him to live in Shibuya, Tokyo. Ueno would commute daily to work, and Hachikō would leave the house to greet him at the end of each day at the nearby Shibuya Station. The pair continued the daily routine until May 21, 1925, when Ueno did not return. The professor had suffered a cerebral hemorrhage while he was giving a lecture to his class, and died without ever returning to the train station at which Hachikō waited.
          Each day, for the next 9 years, 9 months and 15 days, Hachikō awaited Ueno's return, appearing precisely when the train was due at the station.
          </div>
        </div>
        <div className='image p-w-full p-content'>
          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/47/Faithful_Dog_Hachiko_Photo.png/376px-Faithful_Dog_Hachiko_Photo.png" width="376" height="480">
          </img>
        </div>
      </div>
    </div>
    <div className='container gradient-background-1'>
      <div className="flex-grid tborder">
        <div className="letter-item p-margin ">S</div>
        <div className="letter-item p-margin ">A</div>
        <div className="letter-item p-margin ">I</div>
        <div className="letter-item p-margin ">H</div>
        <div className="letter-item p-margin ">U</div>
      </div>
      <div className='content p-content'>
        <div className='image p-w-full p-content'>
          <img src="https://web.archive.org/web/20140712142505im_/http://chinavegan.com/uppic/image/00.jpg" width="365" height="275">
          </img>
        </div>
        <div className='text-container p-w-full p-content'>
          <div className='born-text'>
            Born - NA | Died - 2003
          </div>
          <div className='story-text'>
          Saihu was from Jiujiang, Jiangxi Province, southern China. On 28 November 2003, Master Liu bought a dead dog at the vegetable market to a driving school. The chef was preparing dinner for almost 30 people. The smell of the cooking meat attracted some nearby puppies to the school, along with their mother, Saihu. The chef threw some scraps of meat from the pot to the puppies, but strangely, the puppies' mother prevented them from eating. Saihu also kept barking at the chef, as well as the people who were preparing to eat. Confused but undeterred, the people prepared to eat the meal the chef had made. Saihu became panicked and ran around barking at the guests. Seeing that there was still no response from the crowd, Saihu suddenly jumped up from the ground and let out a long and desolate whimper. Hearing their mother's wailing, four puppies rushed in. Saihu kissed each pup one by one with his wet nose, stretched out his long pink tongue to lick the dirt off the youngest pup, and then burst into tears. He then finally start eating all the scraps the chef had thrown to the dogs. After just a few minutes, Saihu fell dead on the floor. The guests, shocked at the dog's death, stopped eating the meal. They called a policeman as well as some doctors, who discovered poison in the meat. No people or puppies died. Everyone was convinced that Saihu must have smelled the poison and had saved the people and her puppies by sacrificing herself. The people of Jiujaing were so grateful to Saihu that they set up a tomb in a human graveyard and a statue to memorialize the dog.
          </div>
        </div>
      </div>
    </div>
    <div className='container gradient-background-2'>
      <div className="flex-grid tborder">
        <div className="letter-item p-margin ">T</div>
        <div className="letter-item p-margin ">O</div>
        <div className="letter-item p-margin ">G</div>
        <div className="letter-item p-margin ">O</div>
      </div>
      <div className='content p-content'>
        <div className='image p-w-full p-content'>
          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Leonhard_Seppala_with_dogs.jpg/384px-Leonhard_Seppala_with_dogs.jpg" width="384" height="480">
          </img>
        </div>
        <div className='text-container p-w-full p-content'>
          <div className='born-text'>
            Born - 1913 | Died - 1929
          </div>
          <div className='story-text'>
          The 1925 serum run to Nome was a transport of diphtheria antitoxin by dog sled relay across the U.S. territory of Alaska by 20 mushers and about 150 sled dogs across 674 miles (1,085 km) in 5+1/2 days, saving the small town of Nome and the surrounding communities from a developing epidemic of diphtheria. Togo, a Siberian Husky, was the lead dog that led the longest track while the team had the antitoxin. Togo and his team alone ran 146km with temperature around -34 &#x2103; before passing the antitoxin to Balto's team. Seppala, his musher risked the 20 mile sea ice crossing between Cape Denbigh and Point Dexter in a blinding blizzard. Togo's sense of smell permitted them to stay on course and got them to their stopping point on the North shore of Norton Bay, at an Eskimo sod igloo. 
          </div>
        </div>
      </div>
    </div>
    <div className='container gradient-background-1'>
      <div className="flex-grid tborder">
        <div className="letter-item p-margin ">B</div>
        <div className="letter-item p-margin ">O</div>
        <div className="letter-item p-margin ">B</div>
        <div className="letter-item p-margin ">B</div>
        <div className="letter-item p-margin ">I</div>
        <div className="letter-item p-margin ">E</div>
      </div>
      <div className='content p-content'>
        <div className='image p-w-full p-content'>
          <img src="https://upload.wikimedia.org/wikipedia/en/4/4b/Bobbie_the_Wonder_Dog.jpg" width="195" height="250">
          </img>
        </div>
        <div className='text-container p-w-full p-content'>
          <div className='born-text'>
            Born - 1921 | Died - 1927
          </div>
          <div className='story-text'>
          Bobbie (1921-1927) was a Scotch Collie/English Shepherd mix dog who covered 4,105 km on his own to return home to Silverton, Oregon, United States, after he was lost while his owners were visiting family in Wolcott, Indiana. While visiting the family, Bobbie was attacked by three other dogs and ran away. After an exhaustive search, the heartbroken Brazier family were unable to find Bobbie and continued their trip before returning home to Oregon, expecting never to see their dog again. In February 1924, six months later, Bobbie returned to Silverton mangy, dirty, and scrawny, with his toenails worn down to nothing. He showed all the signs of having walked the entire distance, including swimming rivers and crossing the Continental Divide during the coldest part of winter.
          </div>
        </div>
      </div>
    </div>
    <div className='container container-footer'>
      <div >
        Powered by <a href='https://kopoutech.com' >kopoutech</a>
      </div>
    </div>
    </>
  );
}

export default App;
